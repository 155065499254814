import React, { Component } from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, Row } from 'reactstrap';
import {ADMIN_API_HEADER, ADMIN_API_URL, API_HEADER} from './../../../config';
import {connect} from "react-redux";
import {createSelector} from "reselect";
import {updateUser} from "./../../../service/actions/user-action";
import SimpleReactValidator from "simple-react-validator";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from './../../../assets/img/brand/logo.png'
import {Api} from "../../../service/Api";
import LaddaButton, {SLIDE_UP, XL} from 'react-ladda'

const queryString = require('query-string');

class ResetPassword extends Component {
  constructor(props) {
      super(props);
    document.title = 'EnneaApp | Reset Password';
    this.state = {
      password: '',
      msg:'',
      loader:false
    };
    let {history} = this.props;
    let req = {
      token:queryString.parse(this.props.location.search).token,
    };

    Api.forgotPasswordTokenCheck(req, ADMIN_API_HEADER).then((response) => {
      if (response.status === 200) {
          if (response.data.status_code == 400){
            toast.error('Your token is invalid or expired.Please try again', {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 1500
            });
            setTimeout(function () {
              history.push('/login')
            }, 1500);
          }
      }
    }, err => {
      this.setState({msg: 'Something went wrong!'})
    });


    this.validator = new SimpleReactValidator({
      confirmpass: {
        message: 'Confirm password must be same as password.',
        rule: function (val, options) {
          return val === options[0]
        }
      },
    });
  }

  handleSubmit(event) {
    var parsed = queryString.parse(this.props.location.search);
    let token = parsed.token;
    event.preventDefault();
    const {history} = this.props;
    let reqBody = {
      password: this.state.password,
      token:token,
      confirm_password:this.state.confirm_pass
    };
    if (this.validator.allValid()) {
        this.setState({loader:true})
        if(ADMIN_API_HEADER.headers.lang_id){
            delete ADMIN_API_HEADER.headers.lang_id
        }
      Api.resetPassword(reqBody, ADMIN_API_HEADER).then((response) => {
        this.setState({loader:false})
        if (response.status === 200) {
          if (response.data.meta && response.data.meta.status === 1) {
            toast.success(response.data.meta.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1500
            });
            setTimeout(function () {
              history.push('/login');
            }, 1500);
          }else this.setState({msg: response.data.message})
        }else this.setState({msg: response.data.message})
      }, err => {
        this.setState({msg: 'Something went wrong!',loader:false})
      })
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  render() {
    return (
      <div className="app admin-theme flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col className="col-md-7 col-sm-10 col-xs-10 col-lg-6 col-xl-5">
              <CardGroup>
                <Card className="p-4 bg-login">
                  <CardBody>
                    <Form onSubmit={evt => this.handleSubmit(evt)}>
                      <div className="text-center">
                        <img src={logo} width={300} alt="EnneaApp logo"/>
                      </div>
                      <h5 className="text-center fp mb-5">Reset Password</h5>
                      <p className="text-muted text-center">Please enter new password</p>
                      <InputGroup className="mb-3">
                        {/*<InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock"/>
                          </InputGroupText>
                        </InputGroupAddon>*/}
                        <Input type="password" placeholder="Password" name="password" className="input-box-login"
                               value={this.state.password}
                               onChange={(evt) => {this.setState({msg: '', password: evt.target.value})}}
                        />
                      </InputGroup>
                      {this.validator.message('password',this.state.password, 'required|min:6', 'text-danger',{required: 'Password is required.',min: 'Password must be at least 6 characters.'})}

                      <InputGroup className="mb-3">
                        {/*<InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock"/>
                          </InputGroupText>
                        </InputGroupAddon>*/}
                        <Input type="password" name="confirm_pass" placeholder="Confirm Password" className="input-box-login"
                               onChange={(evt) => {this.setState({msg: '', confirm_pass: evt.target.value})}}
                        />
                      </InputGroup>
                      {this.validator.message('confirm_pass',this.state.confirm_pass, 'required|confirmpass:'+this.state.password, 'text-danger',{required: 'Confirm password is required.'})}

                      <p className="text-danger" color="danger">{this.state.msg}</p>
                      <Row>
                        <Col xs="12">
                          <Col xs="12" className="mt-2">

                            <div className="ladda-button">
                              <LaddaButton
                                  loading={this.state.loader}
                                  data-spinner-color="red"
                                  data-style={SLIDE_UP}
                                  data-size={XL}
                                  className="col-12 mt-lg-4 mr-1 btn bg-newcol expand-right">
                                Submit
                              </LaddaButton>
                            </div>
                          </Col>
                          {/*<Button className="px-4 submit-btn bg-newcol col-12 border-0 font-weight-bold mt-2">Submit</Button>*/}
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
        <ToastContainer/>
      </div>
    );
  }
}
const userSelector = createSelector(
  state => state.user,
  user => user,
);

const mapStateToProps = createSelector(
  userSelector,
  (user) => ({
    user
  })
);

const mapActionToProps = {
  onUserUpdate: updateUser
};

export default connect(mapStateToProps, mapActionToProps)(ResetPassword);
