import React, {Component} from 'react';
import {DropdownItem, DropdownMenu, DropdownToggle, Nav} from 'reactstrap';
import PropTypes from 'prop-types';
import {AppHeaderDropdown, AppNavbarBrand, AppSidebarToggler} from '@coreui/react';
import logo from './../../assets/img/brand/onlyTextLogo.png'
import Helpers from "./../../service/Helpers";

const propTypes = {
    children: PropTypes.node,
};

const defaultProps = {};


class DefaultHeader extends Component {
    constructor(props){
        super(props);
        this.state = {
            disabled: false,
        };
        Helpers.events.on('language_dropdown_disable', (data) => {
            if (data === 'disabled') {
                this.setState({disabled: true})
            }else{
                this.setState({disabled: false})
            }
        });
    }
    logout() {
        Helpers.removeLocalStorageData('adminToken');
        Helpers.removeLocalStorageData('_USER');
        Helpers.removeLocalStorageData('langId');
        this.props.history.push('/')
    };

    changePassword() {
        this.props.history.push('/changepassword')
    };

    changeLanguage() {
        this.props.history.push('/change-language')
    };

    render() {

        const {children, ...attributes} = this.props;
        return (
            <React.Fragment>
                <AppSidebarToggler className="d-lg-none" display="md" mobile/>
                <AppNavbarBrand
                    full={{src: logo, width: 150, alt: 'logo'}}
                    minimized={{src: logo, width: 30, alt: 'logo'}}
                />
                <AppSidebarToggler className="d-md-down-none" display="lg"/>

                <div className="language-drop-down">
                    <select disabled={this.state.disabled} name="language" id="language" className="common-input form-control"
                            onChange={(e) => this.props.prevProp.handleChangeLanguage(e)}>
                        <option value="DEFAULT" disabled>Please select Language</option>
                        {this.props.languageList}
                    </select>
                </div>


                <Nav className="ml-auto" navbar>
                    <h5 style={{marginTop: 7}}>{this.props.prevProp.state.adminName ? this.props.prevProp.state.adminName.charAt(0).toUpperCase() + this.props.prevProp.state.adminName.slice(1) : ''
                    }</h5>
                    <AppHeaderDropdown direction="down">
                        <DropdownToggle nav>
                            <i className="fa fa-cog font-4xl setting-icon"></i>
                        </DropdownToggle>
                        <DropdownMenu right style={{right: 'auto', boxShadow: '1px 1px 10px gray', border: 'none'}}>
                            <DropdownItem header tag="div"
                                          className="card-header text-center text-dark"><strong>Account</strong></DropdownItem>
                            {/*<DropdownItem onClick={() => this.changeLanguage()}><i className="fa fa-wrench"/> Change Language</DropdownItem>*/}
                            <DropdownItem onClick={() => this.changePassword()}><i className="fa fa-wrench"/> Change
                                Password</DropdownItem>
                            <DropdownItem onClick={() => this.logout()}><i
                                className="fa fa-power-off"/>Logout</DropdownItem>
                        </DropdownMenu>
                    </AppHeaderDropdown>
                </Nav>
            </React.Fragment>
        );
    }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

export default DefaultHeader;
