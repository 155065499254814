if (process.env.NODE_ENV === 'production') {
  module.exports = {
    API_URL: '',
    ADMIN_API_URL: 'http://192.168.1.61:3012/admin-api/',
    API_ROOT: '',
    API_HEADER: {
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      }
    },
     ADMIN_API_HEADER: {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('adminToken'),
        'language': localStorage.getItem('langId')
      }
    }
  };
} else {
  //http://localhost/zoomin-pdf/prints_4x4/prints_4x4.php?sub_order_id=ZIN-0521a21707-2
  module.exports = {
    API_URL: '',
    ADMIN_API_URL: '',
    API_ROOT: '',
    API_HEADER: {
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      }
    },
    ADMIN_API_HEADER: {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('adminToken'),
        'language': localStorage.getItem('langId')
      }
    }
  };
}

