import axios from "axios";
import Helpers from "./Helpers";
import {toast} from "react-toastify";

const LANG_ID = '5c7391f222db863af29cf772';
let BASE_URL = '';
if (process.env.NODE_ENV === 'production') {
    BASE_URL = 'https://api.enneaapp.com/admin-api/';
} else {
    BASE_URL = 'http://192.168.1.42:3000/admin-api/';
}

const callApi = (method, tag, config, body = "") => axios({
    method: method,
    url: BASE_URL + tag,
    data: body,
    headers: config.headers
}).then(data => {
    if (data.data.status === 401) {
        Helpers.removeLocalStorageData('adminToken');
        Helpers.removeLocalStorageData('_USER');
        Helpers.removeLocalStorageData('langId');
        toast.success(data.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1500
        });
        this.props.history.push('/login')
    } else {
        return data
    }
}).catch((error) => {
    return error.response
});

const Api = {
    dashboard: (reqConfig) => callApi('POST', 'dashboard', reqConfig),
    forgotPassword: (body, reqConfig) => callApi('POST', 'forgot-password', reqConfig, body),
    forgotPasswordTokenCheck: (body, reqConfig) => callApi('POST', 'check-reset-token', reqConfig, body),
    changePassword: (body, reqConfig) => callApi('POST', 'change-password', reqConfig, body),
    language: (body, reqConfig) => callApi('GET', 'language', reqConfig, body),
    login: (body, reqConfig) => callApi('POST', 'login', reqConfig, body),
    resetPassword: (body, reqConfig) => callApi('POST', 'reset-password', reqConfig, body),
    typesData: (body, reqConfig) => callApi('POST', 'types-data', reqConfig, body),
    typesList: (body, reqConfig) => callApi('GET', 'types-list', reqConfig, body),
    typesDetail: (body, reqConfig, id) => callApi('GET', 'get-type_data/' + id, reqConfig, body),
    typesBriefSave: (body, reqConfig) => callApi('POST', 'types-brief', reqConfig, body),
    cmsList: (body, reqConfig) => callApi('GET', 'list-cms', reqConfig, body),
    cmsDetail: (body, reqConfig, id) => callApi('GET', 'get-cms/' + id, reqConfig, body),
    cmsSave: (body, reqConfig) => callApi('POST', 'edit-cms', reqConfig, body),
    questionList: (body, reqConfig) => callApi('POST', 'list-ques', reqConfig, body),
    questionDetail: (body, reqConfig, id) => callApi('GET', 'get-ques/' + id, reqConfig, body),
    questionSave: (body, reqConfig) => callApi('POST', 'types-ques', reqConfig, body),
    consultationList: (body, reqConfig) => callApi('POST', 'list-consult', reqConfig, body),
    consultationDetails: (body, reqConfig,id) => callApi('GET', 'get-consult/'+ id, reqConfig, body),
    consultationDelete: (body, reqConfig, id) => callApi('GET', 'del-consult/' + id, reqConfig, body),
    glossaryList: (body, reqConfig) => callApi('GET', 'list-glossary', reqConfig, body),
    glossaryChangeOrder: (body, reqConfig) => callApi('POST', 'glossary-change-order', reqConfig, body),
    glossaryDelete: (body, reqConfig, id) => callApi('GET', 'del-glossary/' + id, reqConfig, body),
    glossarySave: (body, reqConfig) => callApi('POST', 'add-glossary', reqConfig, body),
    glossaryDetail: (body, reqConfig, id) => callApi('GET', 'get-glossary/' + id, reqConfig, body),
    findYourDetail: (body, reqConfig) => callApi('GET', 'list-type', reqConfig, body),
    findYourTypeSave: (body, reqConfig) => callApi('POST', 'edit-type', reqConfig, body),
    appContentList: (body, reqConfig) => callApi('POST', 'list-message', reqConfig, body),
    appContentDetail: (body, reqConfig, id) => callApi('GET', 'get-message/' + id, reqConfig, body),
    appContentSave: (body, reqConfig) => callApi('POST', 'add-message', reqConfig, body),
    appContentDelete: (body, reqConfig, id) => callApi('GET', 'delete-message/' + id, reqConfig, body),
    webinarDetail: (body, reqConfig) => callApi('GET', 'get-webinar', reqConfig, body),
    webinarList: (body, reqConfig) => callApi('POST', 'list-webinar', reqConfig, body),
    webinarEdit: (body, reqConfig) => callApi('POST', 'edit-webinar', reqConfig, body),
    sendNotification: (body, reqConfig) => callApi('POST', 'send-notification', reqConfig, body),

    eventList: (body, reqConfig) => callApi('GET', 'event-list', reqConfig, body),
    eventSave: (body, reqConfig) => callApi('POST', 'add-edit-event', reqConfig, body),
    eventDetail: (body, reqConfig, id) => callApi('GET', 'event-details/' + id, reqConfig, body),
    eventDelete: (body, reqConfig, id) => callApi('GET', 'delete-event/' + id, reqConfig, body),
    eventChangeOrder: (body, reqConfig) => callApi('POST', 'event-change-order', reqConfig, body),

    emailTemplateList: (body, reqConfig) => callApi('GET', 'email-template-list'+(body && body !== '' ? '?search_word='+body : ''), reqConfig),
    emailTemplateDetail: (body, reqConfig, id) => callApi('GET', 'email-template-details/' + id, reqConfig, body),
    emailTemplateEdit: (body, reqConfig) => callApi('PUT', 'edit-email-template', reqConfig, body),

    deleteWebinarSubscriber: (body, reqConfig) => callApi('POST', 'delete-subscriber', reqConfig, body),

    lookALikesList: (body, reqConfig, id) => callApi('GET', 'lookalike?type_no=' + id, reqConfig, body),
    saveLookALikes: (body, reqConfig) => callApi('POST', 'lookalike', reqConfig, body),

    TwoQuestionTestList: (body, reqConfig) => callApi('GET', 'two-question-test', reqConfig, body),
    TwoQuestionTestEditQuestion:(body, reqConfig) => callApi('POST', 'question', reqConfig, body),
    TwoQuestionTestEditAnswer:(body, reqConfig) => callApi('POST', 'answer', reqConfig, body),   
};

export {Api, LANG_ID,BASE_URL}
