import React, {Component} from 'react';
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  Row
} from 'reactstrap';
import {ADMIN_API_HEADER} from './../../../config';
import {connect} from "react-redux";
import {createSelector} from "reselect";
import {updateUser} from "./../../../service/actions/user-action";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from './../../../assets/img/brand/logo.png'
import {Api} from "../../../service/Api";
import SimpleReactValidator from "simple-react-validator";
import LaddaButton, {SLIDE_UP, XL} from 'react-ladda'

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    document.title = 'EnneaApp | Forgot Password';
    this.state = {
      email: '',
      msg: '',
      loader: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validator = new SimpleReactValidator();
  }

  handleChange(event) {
    event.preventDefault();
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
        msg: '',
        [name]: value
    });
  }

  login(event) {
    event.preventDefault();
    const {history} = this.props;
    history.push('/login');
  }

  handleSubmit(event) {
    event.preventDefault();
    const {history} = this.props;
    let reqBody = {
      email: this.state.email,
    };

    if (this.validator.allValid()){
      this.setState({loader:true})
      if(ADMIN_API_HEADER.headers.lang_id){
          delete ADMIN_API_HEADER.headers.lang_id
      }
      Api.forgotPassword(reqBody, ADMIN_API_HEADER).then((response) => {
        this.setState({loader:false});
        if (response.status === 200) {
          if (response.data.meta && response.data.meta.status === 1) {
            toast.success(response.data.meta.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1500
            });
            setTimeout(function () {
              history.push('/login');
            }, 1500);
          }else this.setState({msg: response.data.message})
        }else this.setState({msg: response.data.message})
      }, err => {
        this.setState({msg: 'Something went wrong!',loader:false})
      })
    }else {
      this.setState({
          msg: ''
      }, () => {
          this.validator.showMessages();
          this.forceUpdate();
      })
    }
  }

  render() {
    return (
      <div className="app admin-theme flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col className="col-md-7 col-sm-10 col-xs-10 col-lg-6 col-xl-5">
              <CardGroup>
                <Card className="p-4 bg-login">
                  <CardBody>
                    <Form onSubmit={evt => this.handleSubmit(evt)}>
                      <div className="text-center">
                        <img src={logo} width={300} alt="EnneaApp logo"/>
                      </div>
                      <div className="text-center">
                      <h5 className="text-center fp mt-3 mb-5">Forgot Password</h5>
                      </div>
                      <InputGroup className="mb-3">
                        <Input type="text" name="email" placeholder="Email address" autoComplete="off"
                               className="input-box-login"
                               value={this.state.email} onChange={(e) => this.handleChange(e)}
                        />
                      </InputGroup>
                      {this.validator.message('Old', this.state.email, 'required|email', 'text-danger', {required: 'Email address is required.', email:"Please enter valid email address."})}
                      <p className="text-danger" color="danger">{this.state.msg}</p>
                      <Row>
                        <Col xs="12" className="mt-2">

                          <div className="ladda-button">
                            <LaddaButton
                                loading={this.state.loader}
                                data-spinner-color="red"
                                data-style={SLIDE_UP}
                                data-size={XL}
                                className="col-12 mt-lg-4 mr-1 btn bg-newcol expand-right">
                              Send Link
                            </LaddaButton>
                          </div>
                        </Col>
                        <Col xs="12" className="text-right">
                          <Button color="link" onClick={evt => this.login(evt)}
                                  className="px-0 forgot-pass">Back to login</Button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
        <ToastContainer/>
      </div>
    );
  }
}

const userSelector = createSelector(
  state => state.user,
  user => user,
);

const mapStateToProps = createSelector(
  userSelector,
  (user) => ({
    user
  })
);

const mapActionToProps = {
  onUserUpdate: updateUser
};

export default connect(mapStateToProps, mapActionToProps)(ForgotPassword);
