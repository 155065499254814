import React, { Component } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultFooter extends Component {
  constructor() {
    super();
    this.state = {
      'year':"2009-"+(new Date()).getFullYear()
    }
  }
  render() {
    // eslint-disable-next-line
    return (
      <React.Fragment>
         &copy;  {this.state.year} <a href="#" rel="noopener noreferrer" className={"sub-order"}><span className="color-footer ">&nbsp;{this.props.projectName}</span></a>
        <span className="ml-auto ">Powered by <a href="https://www.mindinventory.com/" target="_blank" rel="noopener noreferrer" className={"sub-order"}><span className="color-footer">{this.props.poweredBy}</span></a></span>
      </React.Fragment>
    );
  }
}

DefaultFooter.propTypes = propTypes;
DefaultFooter.defaultProps = defaultProps;

export default DefaultFooter;
