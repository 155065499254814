import React from 'react';
import Loadable from 'react-loadable'

function Loading() {
  return <div className="global-loader">
      <div className="-loading -active">
          <div className="-loading-inner">Loading...</div>
      </div>
  </div>;
}

const Dashboard = Loadable({
  loader: () => import('./views/Dashboard'),
  loading: Loading,
});

const ChangePassword = Loadable({
  loader: () => import('./views/Admin/ChangePassword'),
  loading: Loading
});

const ChangeLanguage = Loadable({
    loader: () => import('./views/Admin/ChangeLanguage'),
    loading: Loading
});

const TypeDataCard = Loadable({
    loader: () => import('./views/TypeData/TypeDataCard'),
    loading: Loading
});

const TypeBriefDescriptionList = Loadable({
    loader: () => import('./views/TypeBriefDescription/List'),
    loading: Loading
});

const TypeBriefDescriptionAddRoute = Loadable({
    loader: () => import('./views/TypeBriefDescription/Add'),
    loading: Loading,
});

const cmsListRoute = Loadable({
    loader: () => import('./views/Cms/List'),
    loading: Loading
});

const cmsAddRoute = Loadable({
    loader: () => import('./views/Cms/Add'),
    loading: Loading,
});

const questionsListRoute = Loadable({
    loader: () => import('./views/Question/List'),
    loading: Loading
});

const questionsAddRoute = Loadable({
    loader: () => import('./views/Question/Add'),
    loading: Loading,
});



const ManageConsultation = Loadable({
    loader: () => import('./views/Consultation/List/List'),
    loading: Loading
});

const ViewConsultation = Loadable({
    loader: () => import('./views/Consultation/View/View'),
    loading: Loading
});

const ManageGlossary = Loadable({
    loader: () => import('./views/Glossary/List/List'),
    loading: Loading
});

const AddEditGlossary = Loadable({
    loader: () => import('./views/Glossary/AddEdit/AddEdit'),
    loading: Loading
});

const ManageFindYourType = Loadable({
    loader: () => import('./views/FindYourType/List/List'),
    loading: Loading
});

const ManageAppContent = Loadable({
    loader: () => import('./views/ManageAppContent/List/List'),
    loading: Loading
});

const AddEditAppContent = Loadable({
    loader: () => import('./views/ManageAppContent/Add/Add'),
    loading: Loading
});

const ManageEvent = Loadable({
    loader: () => import('./views/Event/List/List'),
    loading: Loading
});

const AddEditEvent = Loadable({
    loader: () => import('./views/Event/Add/Add'),
    loading: Loading
});

const ManageWebinar = Loadable({
    loader: () => import('./views/ManageWebinar/List/List'),
    loading: Loading
});

const UpdateWebinar = Loadable({
    loader: () => import('./views/ManageWebinar/AddEdit/AddEdit'),
    loading: Loading
});
const Notification = Loadable({
    loader: () => import('./views/SendNotification/AddEdit/AddEdit'),
    loading: Loading
});
const EmailTemplates = Loadable({
    loader: () => import('./views/EmailTemplate/List/List'),
    loading: Loading
});
const EmailTemplatesEdit = Loadable({
    loader: () => import('./views/EmailTemplate/Add/Add'),
    loading: Loading
});

const LookALikesCard = Loadable({
    loader: () => import('./views/LookALikes/LookALikesCard'),
    loading: Loading
});

const QuestionTestCard = Loadable({
    loader: () => import('./views/QuestionTest/QuestionTestCard'),
    loading: Loading
});

let root = {path: '/', exact: true, name: 'Dashboard', component: Dashboard};
let dashboard = {path: '/dashboard', name: 'Dashboard', component: Dashboard};
let changePassword = {path: '/changepassword', name: 'Admin Change Password', component: ChangePassword};
let changeLanguage = {path: '/change-language', name: 'Admin Change Language', component: ChangeLanguage};
let cmsList = {path: '/cms', name: 'CMS', component: cmsListRoute};
let cmsAdd = {path: '/cms-edit/:id', name: 'CMS Edit', component: cmsAddRoute};
let questionList = {path: '/questions', name: 'Manage Questions', component: questionsListRoute};
let questionAdd = {path: '/question/add/:id', name: 'Questions Edit', component: questionsAddRoute};
let TypeData = {path: '/type-data', name: 'Type Data', component: TypeDataCard};
let TypeBriefDescription = {path: '/type-brief-descriptions', name: 'Type Brief Description', component: TypeBriefDescriptionList};
let TypeBriefDescriptionAdd = {path: '/type-brief-description/add/:id', name: 'Type Brief Description Add', component: TypeBriefDescriptionAddRoute};
let consultationData = {path: '/consultation', name: 'Consultation', component: ManageConsultation};
let viewConsultationData = {path: '/consultation-view/:id?', name: 'View Consultation', component: ViewConsultation};
let GlossaryData = {path: '/glossary', name: 'Glossary Data', component: ManageGlossary};
let GlossaryAddEdit = {path: '/glossary-add/:id?', name: 'Add Edit Glossary', component: AddEditGlossary};
let FindYourType = {path: '/find-your-type', name: 'Manage Find Your Type', component: ManageFindYourType};
let AppContent = {path: '/manage-app-content', name: 'Manage App Content', component: ManageAppContent};
let Event = {path: '/manage-event', name: 'Manage Event', component: ManageEvent};
let AppContentAddEdit = {path: '/manage-app-content-add/:id?', name: 'Add Edit App Content', component: AddEditAppContent};
let EventAddEdit = {path: '/manage-event-add/:id?', name: 'Add Edit Event', component: AddEditEvent};
let Webinar = {path: '/manage-webinar', name: 'Manage Webinar', component: ManageWebinar};
let WebinarUpdate = {path: '/update-webinar', name: 'Edit Webinar', component: UpdateWebinar};
let SendNotification = {path: '/send-notification', name: 'Send Notification', component: Notification};
let emailTemplateList = {path: '/email-templates', name: 'Manage Email Templates', component: EmailTemplates};
let emailTemplateEdit = {path: '/email-templates-edit/:id?', name: 'Edit Email Templates', component: EmailTemplatesEdit};
let LookALikes= {path: '/lookalikes', name: "LookALikes", component: LookALikesCard};
let QuestionTest = {path: '/question-test', name: '2 Questions Test', component: QuestionTestCard}

let routes;

routes = [
    root,
    dashboard,
    changePassword,
    changeLanguage,
    TypeData,
    TypeBriefDescription,
    TypeBriefDescriptionAdd,
    cmsList,
    cmsAdd,
    questionList,
    questionAdd,
    consultationData,
    viewConsultationData,
    GlossaryData,
    GlossaryAddEdit,
    FindYourType,
    AppContent,
    AppContentAddEdit,
    Webinar,
    WebinarUpdate,
    SendNotification,
    Event,
    EventAddEdit,
    emailTemplateList,
    emailTemplateEdit,
    LookALikes,
    QuestionTest
]

export default routes;
