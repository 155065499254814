let dashboard = { name: 'Dashboard', url: '/dashboard', icon: 'fa fa-dashboard' };
let typeData = { name: 'Manage Types Data', url: '/type-data', icon: 'icon-layers' };
let TypeBriefDescription = { name: 'Manage Type Brief Description', url: '/type-brief-descriptions', icon: 'fa fa-file-text-o' };
let CMS = { name: 'CMS', url: '/cms', icon: 'icon-grid' };
let Question = { name: 'Manage Questions', url: '/questions', icon: 'fa fa-question' };
let Consultation = { name: 'Manage Consultation', url: '/consultation', icon: 'icon-credit-card' };
let Glossary = { name: 'Manage Glossary', url: '/glossary', icon: 'fa fa-shopping-basket' };
let FindYourType = { name: 'Manage Find Your Type Info', url: '/find-your-type', icon: 'fa fa-cube' };
let ManageAppContent = { name: 'Manage App Content', url: '/manage-app-content', icon: 'fa fa-file-text-o' };
let SendNotification = { name: 'Send Notification', url: '/send-notification', icon: 'fa fa-bell' };
let ManageWebinar = { name: 'Manage Webinar', url: '/manage-webinar', icon: 'fa fa-file-word-o' };
let ManageEvent = { name: 'Manage Event', url: '/manage-event', icon: 'fa fa-file-video-o' };
let ManageEmailTemplates = { name: 'Manage Email Templates', url: '/email-templates', icon: 'fa fa-file-video-o' };
let LookALikes = { name: 'LookALikes', url: '/lookalikes', icon: 'fa fa-file-video-o' };
let QuestionTest = { name: '2 Questions Test', url: '/question-test', icon: 'fa fa-question' };

let menu = [
    dashboard,
    typeData,
    Glossary,
    TypeBriefDescription,
    CMS,
    Question,
    Consultation,
    FindYourType,
    ManageAppContent,
    ManageEvent,
    SendNotification,
    ManageWebinar,
    ManageEmailTemplates,
    LookALikes,
    QuestionTest
];

export default {
  items: menu
}




