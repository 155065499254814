import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Container} from 'reactstrap';
import {AppFooter, AppHeader, AppSidebar, AppSidebarFooter, AppSidebarForm, AppSidebarHeader, AppSidebarMinimizer, AppSidebarNav} from '@coreui/react';
import navigation from '../../_nav';
import routes from '../../routes';
import DefaultFooter from './DefaultFooter';
import DefaultHeader from './DefaultHeader';
import Helpers from "../../service/Helpers";
import {Api} from "../../service/Api";
import {ADMIN_API_HEADER} from "../../config";

class DefaultLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navigation: navigation.items,
            themeChange : Helpers.getLocalStorageData("theme"),
            language: Helpers.getLocalStorageData('langId') ? Helpers.getLocalStorageData('langId') : '',
            languageList: [],
            adminName: Helpers.getLocalStorageJsonData('_USER') && Helpers.getLocalStorageJsonData('_USER').name ? Helpers.getLocalStorageJsonData('_USER').name : '',
        };

        var matchRoutes = require('react-router-config').matchRoutes;
        const branch = matchRoutes(routes, this.props.location.pathname);
        if(branch.length === 0){
            this.props.history.push("/404")
        }
    }

    componentDidMount() {

        Api.language({}, ADMIN_API_HEADER).then((response) => {
            if (response.status === 200) {
                if (response.data.meta.status === 1) {
                    this.setState({
                        languageList: response.data.data
                    });
                } else console.log(response.data.message)
            }else console.log(response.data.message)
        }, err => console.log(err))
    }


    themeData =(no) => {
        Helpers.setLocalStorageData("theme",no);
        this.setState({
            themeChange : Helpers.getLocalStorageData("theme")
        });
    };

    handleChangeLanguage(event) {

        event.preventDefault();
        const target = event.target;
        const value = target.value;
        if (Helpers.getLocalStorageData('langId') !== value) {
            Helpers.setLocalStorageData('langId', value);
            window.location.reload()
        }
    }


    render() {

        let that =this;
        let languages = that.state.languageList.map(language => {
            return (
                <option selected={language.id === that.state.language} value={language.id} key={language.id}> {language.name}</option>
            );
        });

        return (
            <div className={"app "+this.state.themeChange }>
                <AppHeader fixed>
                    <DefaultHeader prevProp={that} languageList={languages} themeFromHeader={this.themeData} {...that.props}/>
                </AppHeader>
                <div className="app-body">
                    <AppSidebar fixed display="lg">
                        <AppSidebarHeader />

                        <AppSidebarForm />
                        <AppSidebarNav navConfig={navigation} {...that.navigation} />
                        <AppSidebarFooter />
                        <AppSidebarMinimizer />
                    </AppSidebar>
                    <main className="main">
                        <Container fluid>
                            <Switch>
                                {routes.map((route, idx) => {
                                        return route.component ? (<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
                                                <route.component {...props} />
                                            )} />)
                                            : (null);
                                    },
                                )}
                                {/*   <Redirect from="/" to="/dashboard" />*/}
                            </Switch>
                        </Container>
                    </main>
                    {/*<AppAside fixed hidden>*/}
                    {/*<DefaultAside />*/}
                    {/*</AppAside>*/}
                </div>
                <AppFooter>
                    <DefaultFooter projectName="EnneaApp" poweredBy={"MINDINVENTORY"}/>
                </AppFooter>
            </div>
        );
    }
}
export default DefaultLayout;
