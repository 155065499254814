import axios from "axios";
import {Link} from "react-router-dom";
import React from "react";
const EventEmitter = require('events');

let Helpers = {
  axios: axios,
  baseUrl: '',
  events : new EventEmitter(),
  setLocalStorageJsonData: (key, data) => {
    localStorage.setItem(key, JSON.stringify(data));
  },
  getLocalStorageJsonData: (key) => {
    return JSON.parse(localStorage.getItem(key));
  },
  setLocalStorageData: (key, data) => {
    localStorage.setItem(key, data);
  },
  getLocalStorageData: (key) => {
    return localStorage.getItem(key);
  },
  removeLocalStorageData: (key) => {
    return localStorage.removeItem(key);
  },
  BREADCRUMB: function (breadcrumbs) {
    let string = [];
    string.push(breadcrumbs.map(function (data, key) {
        return (data.active) ? <li key={key} className="breadcrumb-item"><Link to={data.path}>{data.name}</Link></li> :
        <li key={key} className="breadcrumb-item active">{data.name}</li>;
    }));
    return <ol className="breadcrumb breadcrumb-new"> {string}</ol>;
  },

  EDITOR_TOOLBAR: {
    inline: {inDropdown: false},
    list: {inDropdown: false},
    textAlign: {inDropdown: false},
    link: {},
    history: {},
    fontFamily: {
      options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana','Open Sans'],
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
    },
    colorPicker: {
      className: undefined,
      component: undefined,
      popupClassName: undefined,
      colors: ['#696D6D','#495252','#696D6D','#696D6D','#495252','#1C4094','rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
        'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
        'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
        'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
        'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
        'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)', 'rgb(209,213,216)'],
    },
    fontSize: {
      options: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 36, 48, 60, 72, 96],
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
    },
  }
};

export default Helpers;
