import React, {Component} from 'react';
import {Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, Row} from 'reactstrap';
import {ADMIN_API_HEADER, API_HEADER} from './../../../config';
import Helpers from "./../../../service/Helpers";
import {connect} from "react-redux";
import {createSelector} from "reselect";
import {updateUser} from "./../../../service/actions/user-action";
import logo from './../../../assets/img/brand/logo.png'
import {Api, LANG_ID} from "../../../service/Api";
import SimpleReactValidator from "simple-react-validator";
import LaddaButton, {
    SLIDE_UP,
    XL
} from 'react-ladda'

class Login extends Component {
    constructor(props) {
        super(props);
        document.title = 'EnneaApp | Login';
        this.state = {
            email: '',
            password: '',
            msg: '',
            shown: false,
            shownForm: true,
            loading: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validator = new SimpleReactValidator();
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    forgotpasswordpage(event) {
        event.preventDefault();
        const {history} = this.props;
        history.push('/forgotpassword');
    }

    handleSubmit(event) {
        event.preventDefault();
        let reqBody = {
            email: this.state.email,
            password: this.state.password,
        };

        if (this.validator.allValid()) {

            this.setState({
                loading: !this.state.loading
            });
            Api.login(reqBody, API_HEADER).then((response) => {

                this.setState({loading : false});
                if (response.status === 200) {
                    if (response.data.meta.status === 1) {

                        console.log(response.data.data.token)
                        Helpers.setLocalStorageData('adminToken', response.data.data.token);
                        Helpers.setLocalStorageJsonData('_USER', response.data.data);
                        Helpers.setLocalStorageData('langId', response.data.data.lang_id);
                        this.props.onUserUpdate(response.data.data);
                        const {history} = this.props;
                        window.location = '/dashboard'
                        //history.push('/dashboard');
                    } else this.setState({msg: response.data.meta.message})
                } else this.setState({msg: response.data.message})
            }, err => {
                this.setState({msg: 'Something went wrong!'})
            })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }

    }

    render() {

        var shownForm = {
            display: this.state.shownForm ? "block" : "none"
        };
        return (
            <div className="app admin-theme flex-row align-items-center main-page">
                <Container>
                    <Row className="justify-content-center">
                        <Col className="col-md-7 col-sm-10 col-xs-10 col-lg-6 col-xl-5">
                            <CardGroup>
                                <Card style={shownForm} className="p-4 bg-login">
                                    <CardBody>
                                        <Form onSubmit={evt => this.handleSubmit(evt)}>
                                            <div className="text-center mb-4">
                                                <img src={logo} width={150} height={150} alt="EnneaApp logo"/>
                                            </div>
                                            <p className="text-muted text-center">Log In to your account</p>
                                            <InputGroup className="mb-3">
                                                <Input type="text" placeholder="Email address"
                                                       autoComplete="username"
                                                       className="input-box-login"
                                                       value={this.state.email}
                                                       onChange={(evt) => {this.setState({msg: '', email: evt.target.value})}}
                                                />
                                            </InputGroup>
                                            {this.validator.message('email', this.state.email, 'required|email', 'text-danger',{required: 'Email address is required.', email: 'Please enter valid email address.'})}

                                            <InputGroup className="mb-4">
                                                <Input type="password" placeholder="Password"
                                                       autoComplete="current-password"
                                                       className="input-box-login"
                                                       value={this.state.password}
                                                       onChange={(evt) => {this.setState({msg: '', password: evt.target.value})}}
                                                />
                                            </InputGroup>
                                            {this.validator.message('Password', this.state.password, 'required', 'text-danger',{required: 'Password is required.'})}
                                            <p className="text-danger" color="danger">{this.state.msg}</p>
                                            <Row>
                                                <Col xs="12" className="text-right">
                                                    <Button color="link" onClick={evt => this.forgotpasswordpage(evt)}
                                                            className="px-0 forgot-pass">Forgot password?</Button>
                                                </Col>
                                                <Col xs="12" className="mt-2">

                                                    <div className="ladda-button">
                                                        <LaddaButton
                                                            loading={this.state.loading}
                                                            data-spinner-color="red"
                                                            data-style={SLIDE_UP}
                                                            data-size={XL}
                                                            className="col-12 mt-lg-4 mr-1 btn bg-newcol expand-right">
                                                            Login
                                                        </LaddaButton>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </CardGroup>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

const userSelector = createSelector(
    state => state.user,
    user => user,
);

const mapStateToProps = createSelector(
    userSelector,
    (user) => ({
        user
    })
);

const mapActionToProps = {
    onUserUpdate: updateUser
};

export default connect(mapStateToProps, mapActionToProps)(Login);
