import React, {Component} from 'react';
import {Col, Container, Row} from 'reactstrap';
import axios from "axios";
import {API_URL, ADMIN_API_HEADER} from "../../../config";

class UserAccountVerification extends Component {
  constructor(props) {
    super(props);
    this.state = {msg: 'Yo! your account has been verified.'};
  }

  componentDidMount() {
    let token = this.props.match.params.token;
    if (token !== undefined) {
      let reqBody = {
        "token": token,
      };

      axios.post(`${API_URL}user-account-verification`, reqBody,
        ADMIN_API_HEADER).then((response) => {
        if (response.status === 200) {
          if (response.data.status === 0) {
            this.setState({
              msg: 'Yo! your account has been verified.'
            });

          } else {
            this.setState({
              msg: 'Something wrong....!!'
            });
          }
        }
        else {
          this.setState({
            msg: 'Something wrong....!!'
          });
        }
      })
    } else {
      this.setState({
        msg: "Something wrong....!!"
      })
    }
  }

  render() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <div className="clearfix">
                <h1 className="float-left display-3 mr-4">EnneaApp</h1>
                <h4 className="pt-3">{this.state.msg}</h4>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default UserAccountVerification;
