import React, {Component} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {PrivateRoute} from './helpers/PrivateRoutes';
import './App.css';
// Styles
// CoreUI Icons Set
import '@coreui/icons/css/coreui-icons.min.css';
// Import Flag Icons Set
import 'flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
import './assets/css/style.css'

// Containers
import {DefaultLayout} from './containers';
// Pages
import {Login, Page404, Page500} from './views/Pages';
import ForgotPassword from "./views/Pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./views/Pages/ResetPassword/ResetPassword";
import UserAccountVerification from "./views/Pages/UserAccountVerification";

// import { renderRoutes } from 'react-router-config';
// const history = createHistory({ basename: "/EnneaAppAdmin" });
class App extends Component {
    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route path="/reset-password" name="Reset Password Page"
                           component={ResetPassword}/>
                    <Route path="/forgotpassword" name="Forgot Password Page" component={ForgotPassword}/>
                    <Route exact path="/login" name="Login Page" component={Login}/>
                    <Route path="/404" name="Page 404" component={Page404}/>
                    <Route path="/500" name="Page 500" component={Page500}/>
                    <PrivateRoute path="/" name="Dashboard" component={DefaultLayout}/>
                </Switch>
            </BrowserRouter>
        );
    }
}

export default App;
