import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import userReducer from './service/reducer/user-reducer'
import {Provider} from "react-redux";
import {applyMiddleware, combineReducers, compose, createStore} from 'redux'
import thunk from "redux-thunk";
// import registerServiceWorker from './registerServiceWorker';
import './index.css';
import App from './App';
import Helpers from "./service/Helpers";
import { LANG_ID } from "./service/Api";


if(Helpers.getLocalStorageData("langId") === null){
    Helpers.setLocalStorageData('langId', LANG_ID);
}

/*if(Helpers.getLocalStorageData("adminToken")){

    let Token = Helpers.getLocalStorageData("adminToken").replace(/"/gi,"");
    /!*JWT.verify(Token, 'abc123456789',function(err){
      if(err){

          console.log(err);
          /!*Helpers.removeLocalStorageData('adminToken');
            Helpers.removeLocalStorageData('_USER');*!/
      }
    })*!/
}*/

const allReducer = combineReducers({
  user: userReducer
});


const allStoreEnhancers = compose(
  applyMiddleware(thunk),
  //window.devToolsExtension && window.devToolsExtension(),
);


const store = createStore(
  allReducer, {
    user: [],
  },
  allStoreEnhancers
);
// console.log(store.getState());
ReactDOM.render(<Provider store={store}><App/></Provider>, document.getElementById('root'));
// disable ServiceWorker
// registerServiceWorker();
